<template>
  <div>
    <a-form ref="form" layout="vertical" :model="form" class="formCon">
      <a-row>
        <a-col :span="12">
          <a-form-item label="服务项目" name="orderNumber">
            <a-input v-model:value.trim="serviceName" :disabled="true" placeholder="输入机构名"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="机构名" name="orderNumber">
            <a-input v-model:value.trim="orgName" :disabled="true" placeholder="输入机构名"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item name="numberAfter" :rules="[{ required: true, message: '请输入运营系数' }]">
            <div class="label-style" slot="label">
              <div>
                <span class="ls-required">*</span>
                <span>运营系数</span>
              </div>
              <a-popover placement="top">
                <template #content>
                  <p class="ls-content">门店综合权重*运营系数=总分</p>
                </template>
                <question-circle-outlined class="ls-icon" />
              </a-popover>
            </div>
            <div style="display: flex; align-items: center;">
              <span>当前运营系数{{ form.numberBefore }}，修改为</span>
              <a-input-number :min="0.01" :precision="2" style="width:200px; margin-left: 10px;" v-model:value.trim="form.numberAfter"
                placeholder="输入运营系数"></a-input-number>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="修改原因" name="reason" :rules="[{ required: true, message: '请输入修改原因' }]">
            <a-input maxlength="30" v-model:value.trim="form.reason" placeholder="输入修改原因"></a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <div style="margin: 30px 0; font-size: 18px;">修改记录</div>
      <a-table :data-source="dataSource" :columns="columns" :scroll= '{ x: 600 }',>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'updateTime'">
            <span>
              {{ getDate(record.updateTime) }}
            </span>
          </template>
        </template>
      </a-table>
      <div style="margin-top: 30px;">
        <a-form-item>
          <a-button type="primary" @click="submit" :loading="submitLoading">确认</a-button>
        </a-form-item>
      </div>
    </a-form>
  </div>
</template>

<script>
import { get } from "@/utils/http";
import TablePage from "@/components/TablePage";
import dayjs from 'dayjs';
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      form: {
        id: '',
        numberBefore: '',
        numberAfter: '',
        reason: ''
      },
      orgName: '',
      serviceName: '',
      dataSource: [],
      columns: [
        {
          title: '修改原因',
          dataIndex: 'reason',
          key: 'reason',
          width: 200,
          ellipsis: true
        },
        {
          title: '修改后系数',
          dataIndex: 'numberAfter',
          key: 'numberAfter',
          width: 100
        },
        {
          title: '操作人',
          dataIndex: 'userName',
          key: 'userName',
          width: 100
        },
        {
          title: '操作时间',
          dataIndex: 'updateTime',
          key: 'updateTime',
          width: 100
        },
      ],
    }
  },
  created() {
    this.getDetail(this.selectedRows[0].id);
    this.serviceName = this.selectedRows[0].serviceName;
  },
  mounted() {

  },
  methods: {

    async getDetail(id) {
      this.detailLoading = true;
      try {
        let res = await get("/sortrule/operation/serviceProject/edit/get", { id: id })
        if (res.code == '200') {
          this.form.id = res.data.id;
          this.form.numberBefore = res.data.number;
          this.orgName = res.data.orgName;
          this.dataSource = res.data.updateList
        }
      } catch (e) {
        this.$message.error(e.message)
      }
      this.detailLoading = false;
    },

    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      this.submitLoading = true;
      let { numberBefore, numberAfter, reason } = this.form;
      try {
        await this.$http.post('/sortrule/operation/serviceProject/edit', { id: this.selectedRows[0].id, numberBefore, numberAfter, reason });
        this.$message.success('编辑成功');
        this.getDetail(this.selectedRows[0].id);
        this.$refs.form.resetFields();
        // this.$emit('close');
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    getDate(val) {
      if (val) {
        return dayjs(val).format('YYYY-MM-DD HH:mm')
      } else {
        return '-'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ic-title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}

.formCon {
  .ant-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .ant-col {
      width: 48%;
      margin: 0 5px;
      flex: 1;
    }
  }
}

.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}
</style>
